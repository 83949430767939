import { render, staticRenderFns } from "./SmsVerification.vue?vue&type=template&id=53737f6c&scoped=true"
import script from "./SmsVerification.vue?vue&type=script&lang=js"
export * from "./SmsVerification.vue?vue&type=script&lang=js"
import style0 from "./SmsVerification.vue?vue&type=style&index=0&id=53737f6c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53737f6c",
  null
  
)

export default component.exports