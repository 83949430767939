<template>
    <table class="table text-center">
        <thead>
            <tr class="text-label">
                <td class="hidden-sm">{{ $t('orders.label_create_time') }}</td>
                <th class="text-center">{{ $t('orders.label_amount') }}</th>
                <th class="text-right">{{ $t('orders.label_create_price') }}</th>
                <th class="text-right">{{ $t('orders.label_close_price') }}</th>
                <th class="text-right">{{ $t('orders.label_fee') }}</th>
                <th class="text-right">{{ $t('orders.label_profit') }}</th>
            </tr>
        </thead>
        <tbody v-if="!recent_orders">
            <tr>
                <td colspan="6">
                    <loading-indicator />
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-if="recent_orders.length === 0">
                <td colspan="6">
                    <div class="no-data-indicator m-5 text-content">
                        {{ $t('orders.no_orders') }}
                    </div>
                </td>
            </tr>
            <tr v-for="(order, index) in recent_orders" :key="index" class="text-content">
                <td class="hidden-sm">{{ new Date(order.timeCreated).formatTime(true) }}</td>
                <td class="text-center">
                    {{ order.amount }}
                    <!-- <small v-if="order.mining()" class="badge badge-info">@(TradeSR.Minifutures_OrderList_Mining)</small> -->
                </td>
                <td class="text-right">
                    <span v-if="order.buyUp" class="color-up">
                        {{ order.createPrice }}
                        <i class="fa fa-arrow-up"></i>
                    </span>
                    <span v-else class="color-down">
                        {{ order.createPrice }}
                        <i class="fa fa-arrow-down"></i>
                    </span>
                </td>
                <template v-if="order.status === 4">
                    <template v-if="order.errorCode === 0">
                        <td class="text-right">{{ order.closePrice }}</td>
                        <td class="text-right">{{ order.fee }}</td>

                        <td v-if="order.profit >= 0" class="color-up text-right">+{{ order.profit }}</td>
                        <td v-else class="color-down text-right">{{ order.profit }}</td>
                    </template>
                    <template v-else>
                        <td class="text-right">--</td>
                        <td class="text-right">{{ order.fee }}</td>
                        <td class="small text-right">{{ $t('orders.status_invalid') }}</td>
                    </template>
                </template>

                <template v-else>
                    <td class="text-right">--</td>
                    <td class="text-right">--</td>
                    <td class="text-right">--</td>
                </template>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: ['symbol'],

    data() {
        return { recent_orders: null };
    },

    created() {
        this.getRecentOrders();
    },

    methods: {
        getRecentOrders: function () {
            const self = this;
            $.callPostApi(self, '/api/v1/boptionorder/recentorders?id=' + encodeURIComponent(this.symbol.metadata.id)).then((json) => {
                if (json && json.errcode === 0) {
                    self.recent_orders = json.data;
                }
            });
        }
    }
};
</script>
<style scoped>
    .order-container .table .tr .th,.order-container .table .tr .td{
        border-bottom: 2px solid #1f2023;
    }
</style>