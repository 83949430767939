<template>
    <div class="prompted-symbol">
        <div class="title">
            <!-- <token-icon :symbol="symbol" /> -->
            <img class="mr-5 m-t-2" src="../../../assets/images/eu3/little-angle.png" alt="">
            <span>{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</span>
            <div  class="percentage" :class="symbol.up ? 'up' : 'down'">
                <!-- <span>{{symbol.up ? '-' : '+'}}</span> -->
                {{  numShortener(symbol.price_change_pct.split('%')[0]) }}%
            </div>
        </div>
        <div class="flex-box">
            <div class="price">${{ symbol.price }}</div>
            <img v-if="symbol.up" src="../../../assets/images/eu3/curve-graph.png">
        </div>
        <!-- <div class="token-icon-wrapper d-flex justify-content-center">
            <token-icon :symbol="symbol" />
        </div>
        <div class="symbol-content">
            <h2>{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</h2>
            <div :class="symbol.up ? 'up' : 'down'">{{ numShortener(symbol.price_change_pct.split('%')[0]) }}%</div>
            <div class="price">{{ symbol.price }}</div>
        </div> --> 
    </div>
</template>

<script>
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['symbol'],

    methods: {
        numShortener: function (num) {
            let result;
            if (!isNaN(num)) {
                num = Number(num);
                result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
            } else {
                result = num;
            }
            return result;
        }
    }
};
</script>

<style scoped>
.prompted-symbol{
    width: 100%;
    height: 100px;
    background:  rgba(122, 40, 173, 0.29);
    border-radius: 6px;
    padding: 10px 20px;
    border: 1px solid ;
    /* border-image: linear-gradient(181deg, #A182B4 -4%, #000000 26%, #000000 47%, #000000 70%, #A182B4 99%) 1; */
}
.title{
    display: flex;
    align-items: center;
    color:  #fff;
}
.mr-5{
    margin-right:5px;
}
.m-t-2{
    margin-top:2px;
}
.flex-box{
    display:flex;
    justify-content: space-between;
    margin-top: 24px;
}
.percentage{
    margin: auto;
    margin-right: 0px;
    width:68px;
    text-align: center;
    
}
.page-top .prompted-symbol .price{
    /* background:  rgba(122, 40, 173, 0.29); */
    margin-top: 10px;
    padding-left: 5px;
}

.title .up {
    /* color: var(--up); */
    background-color: #279ceb;
}

/* .title .up::before {
    content: url('@/assets/images/rising-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
} */
.title .down {
    color: var(--down);
}
.title .down::before {
    content: url('@/assets/images/falling-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}
</style>