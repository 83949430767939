<template>
    <div ref="checkInModal" class="modal" tabindex="-1" role="dialog" >
        <div class="checkIn-dialog">
            <div class="gif">
                <img class="round" src="@/assets/images/checkin/icon_sun.png" alt="">
                <img class="gif-box" src="@/assets/images/checkin/checkin.gif" alt="">
            </div>
            <!-- <button  type="button" class="btn btn-primary  btn-lg btn-checkin" :disabled="hasChecked" @click="checkinDialog()">
                {{ hasChecked?$t("coin_treasure.signed_in"):$t("coin_treasure.sign_in") }}
            </button> -->
            <div class="desc">{{$t('coin_treasure.checkin_get')}}  {{data.bonusToken}} <span class="bonus">+{{ data.bonus }}</span></div>
            <div class="tip" >{{ $t("coin_treasure.signed_in_daynum",{numConsecutiveDays:data.numConsecutiveDays}) }}</div>
            <div class="close" @click="parentfunc()">
                <svg t="1721053749092" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4569" width="200" height="200"><path d="M553.708426 511.998977l318.623781-318.623781c11.988032-11.988032 12.301164-31.112602 0.699941-42.714847l-1.399883-1.399883c-11.602246-11.602246-30.725792-11.288091-42.713824 0.700965L510.293637 468.584188 191.669856 149.96143c-11.988032-11.989055-31.112602-12.302187-42.714847-0.700965l-1.399883 1.400906c-11.602246 11.602246-11.288091 30.725792 0.700965 42.714847l318.623781 318.622758L148.255067 830.621734c-11.989055 11.989055-12.302187 31.112602-0.700965 42.714847l1.399883 1.399883c11.602246 11.602246 30.726815 11.288091 42.714847-0.699941l318.623781-318.623781 318.623781 318.623781c11.988032 11.989055 31.111578 12.302187 42.713824 0.700965l1.399883-1.399883c11.602246-11.602246 11.288091-30.726815-0.699941-42.714847L553.708426 511.998977z" fill="#ffffff" p-id="4570"></path></svg>
            </div>
        </div>
    </div>
</template>

<script>
// import { savingRedeem } from '../request';
import { Modal } from 'bootstrap';

export default {
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },

    data() {
        return {
            checkedStatus: false,
            amount: '',
            balance: 0,
            interestRates: '',
            numDays: '',
            submitting: false,

            // The confirmation popup modal
            popupModal: null
        };
    },

    mounted() {
        this.popupModal = new Modal(this.$refs.checkInModal);
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        parentfunc() {
            this.$parent.hideDialog();
            // this.popupModal.hide();
        },
        hideModal() {
            this.popupModal.hide();
        },
        showModal() {
            this.popupModal.show();
        },
    }
};
</script>
 <style scoped lang="less">
    .checkIn-dialog {
        position: absolute;
        width: 305px;
        background: #fff;
        border-radius: 8px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .gif {
            position: relative;
            min-height: 100px;
        }
        .gif-box {
            width: 260px;
            display: block;
            position: absolute;
            margin: 0 auto;
            top: -145px;
            left: 50%;
            transform: translateX(-50%);
        }
        .round {
            position: absolute;
            top: -145px;
            // left: 20px;
            width: 305px;
            animation: loading 4s linear infinite;
        }
        .tip {
            text-align: center;
            padding: 10px 0 10px 0;
            font-size: 12px;
            position: relative;
            // top: -20px;
        }
        .desc {
            color: #fe8b07;
            text-align: center;
            .bonus {
                font-size: 22px;
                font-weight: bold;
            }
        }
       
    }
    .close {
        margin-top: 50px;
        text-align: center;
        position: absolute;
        right: 0;
        top: -166px;
        .icon {
            width: 30px;
            height: 30px;
            // border: 1px solid #fff;
            // border-radius: 50%;
        }
    }
    @keyframes loading {
        0% {
            transform: rotate(0deg) ;
        }
        100% {
            transform: rotate(360deg) ;
        }
    }
 </style>
