<template>
    <form action="javascript:void(0);" method="post" class="login-form" @submit="do_submit">
        <div class="title-box">
            <img src="@/assets/images/eu3/loginLogoMd2x.png" alt="" id="login-logo">
            <h6 class="login-title">{{ $t('reset_password.title') }}</h6>
        </div>
        <div class="page-top-nav mb-3" v-if="sysconfig.phoneSupported && sysconfig.emailSupported">
            <li :class="{ active: !emailVCode }">
                <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
            </li>
            <li :class="{ active: emailVCode }">
                <a @click="emailVCode = true" href="javascript:;">{{ $t('register.label_email') }}</a>
            </li>
        </div>
        <div class="form-group">
            <label class="form-label">{{ $t('home.register_place_holder') }}</label>
            <template v-if="emailVCode">
                <!-- Email account -->
                <!-- Required by the interface -->
                <input type="hidden" name="RegionCode" value="+1" />
                <input v-model="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" :placeholder="$t('register.label_email')" />
            </template>
            <div v-else  class="d-flex">
                <region-selector v-bind:regions="regions" :regionCode="regionCode" @change="onRegionCodeChanged" />
                <div class="flex-fill">
                    <input
                        name="Name"
                        ref="accountInput"
                        v-model="account"
                        pattern="\d*"
                        type="text"
                        class="form-control phone round-right"
                        data-val="true"
                        data-val-required="*"
                        :placeholder="$t('reset_password.label_account')" />
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="form-label">{{ $t('reset_password.label_password') }}</label>
            <div class="password-box">
                <input
                    name="Password"
                    v-model="password"
                    :type="showPassword?'text':'password'"
                    autocomplete="new-password"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    :placeholder="$t('reset_password.label_password')"
                />
                <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group">
            <label class="form-label">{{ $t('reset_password.label_confirm_password') }}</label>
            <div class="password-box">
                <input
                    name="ConfirmPassword"
                    v-model="confirmPassword"
                    :type="showConfirmPassword?'text':'password'"
                    autocomplete="new-password"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    data-val-equalto-other="Password"
                    data-val-equalto="*"
                    :placeholder="$t('reset_password.label_confirm_password')"
                />
                <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <sms-verification :regionCode="regionCode" :account="account" action="reset-password" />
        <div class="form-group">
          <div class="btn-box">
            <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
                {{ $t('reset_password.title') }}
            </button>
          </div>
        </div>
</form>
</template>
<style scoped>
.password-box {
  position: relative;
}
.password-icon {
  position: absolute;
  right: 15px;
  bottom: 50%;
  transform: translateY(50%);
  cursor: pointer;
}
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';
export default {
  components: { RegionSelector },
  props: ['regions'],
  data() {
      return {
          emailVCode: false,
          account: null,
          regionCode: null,
          password: null,
          confirmPassword: null,

          showInputs: true,

          submitting: false,
          completed: false,
          showPassword:false,
          showConfirmPassword:false
      };
  },

  mounted() {
      $.resetValidators();
  },
  watch: {
      emailVCode: function () {
          Vue.nextTick(() => {
              $.resetValidators();
          });
      }
  },
  methods: {
      // goNextPage: function (e) {
      //     const frm = $(e.target);
      //     if (frm.valid()) {
      //         this.showInputs = false;
      //     }
      //     return false;
      // },

      // goPrevPage: function () {
      //     this.showInputs = true;
      // },

      do_submit: function (e) {
          const frm = $(e.target);
          const self = this;

          if (frm.valid()) {
              this.submitting = true;

              const data = frm.serialize();
              $.callPostApi(self, '/api/v1/account/resetpassword', data)
                  .then((json) => {
                      if (json && json.errcode === 0) {
                          // redirect to home page
                          self.completed = true;

                          setTimeout(function () {
                              self.$router.push('/user');
                          }, 500);
                      } else {
                          $.top_error(json.errmsg);
                      }
                  })
                  .catch(function (err) {
                      $.top_error(self.$t('general.operation_error'));
                  })
                  .then(() => {
                      self.submitting = false;
                  });
          }
      },

      focusAccount: function () {
          this.$refs.accountInput.focus();
      },

      onRegionCodeChanged: function (val) {
          this.regionCode = val;
      }
  }
};
</script>
<style type="text/css" scoped>
.login-form {
    width: 100%;
}
.title-box {
    display: none;
}
.page-top-nav{
    display: flex;
    margin-bottom: 2.375rem !important;
}
.page-top-nav li{
    width: 50%;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}
.page-top-nav li a {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    height: 100%;
    line-height: 50px;
}
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
.form-label {
    display: none;
}
#login-page .btn-box {
    background: #5CCD9E;
    box-shadow: 0px 4px 0px 0px rgba(0,0,0,0.3), inset 0px -4px 0px 0px #1F7853;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid;
    padding: 4px 4px 8px;
    margin-top: 30px;
}
#login-page button.btn-submit {
    margin: 0;
    display: block;
    width: 100%;
    height: 100%;
    padding: .625rem 0;
    background: linear-gradient( 180deg, #AEEDD2 0%, #92F6CC 100%);
    box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.3), inset 0px -9px 10px 0px #74DCB0;
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #1F7853;
    font-size: 20px;
}
.help-block {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #7F8490;
}
.form-group {
    margin-bottom: 1.5625rem;
    font-size: 16px;
}
.form-group .form-control {
    font-size: 16px;
    outline: none;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid #7F8490;
    background: none;
    padding: 15px 20px;
}
.form-group .form-control:focus {
    outline: none;
    border-color: #000000;
}
.login-link {
    font-size: 12px;
    color: #7F8490;
}
@media (max-width: 768px) {
    .login-page .form-group {
        margin-bottom: 10px;
    }
    .login-page .form-group label {
        display: block;
        font-family: PingFang SC, PingFang SC;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 35px;
    }
    .form-group .form-control {
        border: 1px solid #FFFFFF;
        border-radius: 8px;
        background: rgba(241,242,245,0.3);
        padding: 13px 16px;
        color: #FFFFFF;
    }
    .form-group .form-control::placeholder {
        color: #FFFFFF;
    }
    .form-group .form-control:focus {
        border: 1px solid #FFFFFF;
    }
    .page-top-nav li.active a {
        color: #FFFFFF;
    }
    #login-page button.btn-submit {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
    }
    .page-top-nav {
        margin-bottom: 1rem !important;
    }
    #login-page .btn-box {
        margin-top: 30px;
    }
    .title-box {
        display: block;
    }
    #login-logo {
        width: 47px;
        height: auto;
        margin: 0;
        margin-bottom: 16px;
    }
    .login-title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
    }
}
</style>