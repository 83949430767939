<template>
    <div class="row">
        <div class="col balance-list">
            <div class="total">
                <div class="actions"></div>
            </div>

            <dl class="title-row">
                <dt>
                    <div>{{ $t('assets.account_type') }}</div>
                    <div>{{ $t('assets.balance') }}</div>
                    <div class="hidden-sm">{{ $t('assets.available_balance') }}</div>
                    <div class="hidden-sm">{{ $t('assets.frozen') }}</div>
                    <div class="hidden-sm"><!-- deposit/withdraw actions --></div>
                </dt>
            </dl>

            <loading-indicator v-if="!balance_list" />

            <dl v-else v-for="balance in balance_list" class="currency-row" :key="balance.currency">
                <dd class="dis-pc">
                    <div>{{ getTokenName(balance) }}</div>
                    <div>{{ balance.balance }}</div>
                    <div v-if="balance.currency === 'FTUSDT'">{{ futures_assets }}</div>
                    <div v-else>{{ balance.available }}</div>
                    <div>{{ balance.frozen }}</div>
                    <div class="actions">
                        <template v-if="isDepositSupported(balance.currency)">
                            <a href="javascript:;" class="link-deposit" @click="toggleDeposit(balance.currency)">
                                {{ $t('general.deposit') }}
                            </a>
                            <router-link
                                :to="'/finance/withdraw/' + encodeURIComponent(balance.currency.toLowerCase())">{{
                                    $t('general.withdraw') }}</router-link>
                        </template>
                        <template v-else-if="balance.currency !== 'FTUSDT'">
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.deposit') }}</a>
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.withdraw') }}</a>
                        </template>
                    </div>
                </dd>
                <dd class="dis-mb">
                    <div class="balance-mb-title">
                        <div>{{ getTokenName(balance) }}</div>
                        <div class="d-flex align-items-center">
                            <div>{{ balance.balance }}</div>
                            <img src="@/assets/images/eu3/arrow_right.png" alt="" class="eyes"
                                :class="{ 'rotate-img': !isRotatedMap[balance.currency] }"
                                @click="toggleShowMoney(balance.currency)">
                        </div>
                    </div>
                    <div v-if="!showMoneyMap[balance.currency]" id="list-balance">
                        <div id="list-balance-top">
                            <div class="title">
                                <div>{{ $t('assets.available_balance') }}</div>
                                <div>{{ $t('assets.frozen') }}</div>
                            </div>
                            <div>
                                <div v-if="balance.currency === 'FTUSDT'">{{ futures_assets }}</div>
                                <div v-else>{{ balance.available }}</div>
                                <div>{{ balance.frozen }}</div>
                            </div>
                        </div>
                        <div class="actions">
                            <template v-if="isDepositSupported(balance.currency)">
                                <a href="javascript:;" class="link-deposit" @click="toggleDeposit(balance.currency)">
                                    {{ $t('general.deposit') }}
                                </a>
                                <router-link
                                    :to="'/finance/withdraw/' + encodeURIComponent(balance.currency.toLowerCase())">{{
                                        $t('general.withdraw') }}</router-link>
                            </template>
                            <template v-else-if="balance.currency !== 'FTUSDT'">
                                <a href="javascript:;" class="disabled" disabled>{{ $t('general.deposit') }}</a>
                                <a href="javascript:;" class="disabled" disabled>{{ $t('general.withdraw') }}</a>
                            </template>
                        </div>
                    </div>
                </dd>
                <!-- display deposit address -->
                <div v-if="isDepositSupported(balance.currency) && deposit_currency === balance.currency"
                    class="deposit-address-block">
                    <deposit-form :bcConfig="bcConfig" :coinConfig="getCoinConfig(balance.currency)" />
                </div>
            </dl>

        </div>
    </div>
</template>

<script>
import DepositForm from '../../Finance/Components/DepositForm.vue';

export default {
    components: { DepositForm },
    props: ['balance_list', 'futures_assets', 'bcConfig'],

    data() {
        return {
            coinMap: {},

            // The active currency for deposits
            deposit_currency: '',

            //showMoney-showActions
            showMoneyMap: {},
            isRotatedMap: {}
        }
    },

    created() {
        // Build coin map
        const coins = (this.bcConfig ? this.bcConfig.supportedCoins : null) || [];
        if (!coins.length === 0)
            throw new Error('Tokens are not properly configured.');

        const map = {};
        $(coins).each((index, item) => {
            map[item.currency.toUpperCase()] = Object.freeze(item);
        });
        this.coinMap = Object.freeze(map);

        //Initialize the corresponding switch state for each balance.
        this.balance_list && this.balance_list.forEach(balance => {
            this.$set(this.showMoneyMap, balance.currency, false);
            this.$set(this.isRotatedMap, balance.currency, false);
        });
    },

    methods: {
        /**
         * Determines whether supports deposits for the specified coin or not.
         * @param {string} coin The name of the coin.
         */
        isDepositSupported: function (coin) {
            return !!this.coinMap[coin];
        },

        getTokenName: function (token) {
            switch (token.currency) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token.display_name;
            }
        },

        toggleDeposit: function (currency) {
            this.deposit_currency = this.deposit_currency === currency ? null : currency;
        },

        getCoinConfig: function (coin) {
            return this.coinMap[coin.toUpperCase()];
        },

        toggleShowMoney(currency) {
            this.$set(this.showMoneyMap, currency, !this.showMoneyMap[currency]);
            this.$set(this.isRotatedMap, currency, !this.isRotatedMap[currency]);
        },
    }
}
</script>
<style scoped>
.rotate-img {
    transform: rotate(90deg);
}

.actions a {
    color: #935BCA;
    font-size: 12px;
}

.actions .disabled {
    color: #ddd;
}

.dis-mb {
    display: none;
}

@media (max-width: 1024px) {
    .dis-mb {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .dis-pc {
        display: none;
    }

    .balance-mb-title {
        display: flex;
        justify-content: space-between;
    }

    .eyes {
        width: 7px;
        height: 10px;
        margin-left: 10px;
    }

    #list-balance {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    #list-balance-top {
        display: flex;
        justify-content: space-between;
        background-color: #2b2929;
        border-radius: 5px;
        padding: 10px;
        margin-top: 10px;
    }

    .title {
        color: #03F3F3;
        text-align: left;
    }
}
</style>