<template>
    <div class="content-top">
        <div class="left">
            <div class="trading-chart">
                <div class="ticker bg-title text-content">
                    <div stop="1" class="symbol-name">
                        <div class="d-flex flex-row">
                            <div class="dropdown" @click.stop="toggleDropdownLeft">
                                <button class="btn btn-secondary custom-arrow" type="button">
                                    {{ $t('general.futures') }}
                                </button>
                                <div v-show="isDropdownVisibleLeft" class="side_right">
                                    <router-link to="/futures" class="dropdown-item">{{ $t('general.futures') }}</router-link>
                                    <router-link to="/exchange" class="dropdown-item">{{ $t('general.exchange') }}</router-link>
                                    <router-link v-if="sysconfig.bOptionEnabled" to="/boption" class="dropdown-item">{{ $t('general.boption') }}</router-link>
                                </div>
                            </div>
                            <div class="dropdown" @click.stop="toggleDropdown">
                                <button class="btn btn-secondary custom-arrow" type="button">
                                    {{ symbol.metadata.name }}
                                </button>
                                <div v-show="isDropdownVisible" class="side_right">
                                    <div class="futures-content">
                                        <router-link v-for="sym in symbols" :key="sym.id" :to="'/futures/' + sym.symbol.toLowerCase()" class="dropdown-item">
                                            {{ sym.name }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div growing-ignore="true" class="price-container" :class="{ 'color-up': symbol.up, 'color-down': !symbol.up }">
                        <div>{{ symbol.price }}</div>
                        <div class="price-change">{{ symbol.price_change }}</div>
                    </div>
                    <dl growing-ignore="true" class="change">
                        <dt class="text-label">{{ $t('futures.24h_change') }}</dt>
                        <dd v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price_change_pct }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="low d-none d-sm-block">
                        <dt class="text-label">{{ $t('futures.24h_low') }}</dt>
                        <dd>{{ symbol.day_low }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="high d-none d-sm-block">
                        <dt class="text-label">{{ $t('futures.24h_high') }}</dt>
                        <dd>{{ symbol.day_high }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="amount d-none d-sm-block">
                        <dt class="text-label">{{ $t('futures.24h_vol') }}</dt>
                        <dd>{{ symbol.day_vol }}</dd>
                    </dl>
                    <div class="goto-chart" @click="showChart"><i class="fa fa-chart-bar"></i></div>
                </div>
                <div class="trading-chart-container bg-content">
                    <div class="chart-container">
                        <div id="tv_chart_container" class="chart">
                            <!-- Display the loading indicator by default -->
                            <loading-indicator />
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom-trade">
                <div class="row">
                    <div class="col">
                        <button class="btn-submit bg-buy" @click="showTrade(true)">{{ $t('futures.label_buy_up') }}</button>
                    </div>
                    <div class="col">
                        <button class="btn-submit bg-sell" @click="showTrade(false)">{{ $t('futures.label_buy_down') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="futures-trade">
            <div class="order-books">
                <div class="mod-body bg-content">
                    <dl>
                        <dt class="bg-title text-label">
                            <span class="price">{{ $t('futures.depth_price') }} ({{ symbol.metadata.quote_symbol }})</span>
                            <span class="amount">{{ $t('futures.depth_volume') }} ({{ $t('futures.label_unit') }})</span>
                            <span class="total">{{ $t('futures.depth_total_volume') }} ({{ $t('futures.label_unit') }})</span>
                        </dt>
                        <dd class="now-pric text-content">
                            <div class="now-price">
                                <dl>
                                    <dt v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price }}</dt>
                                </dl>
                            </div>
                            <div class="asks">
                                <p @click="onPriceSelected(order.price)" v-for="order in order_books.asks" v-bind:key="order.key">
                                    <span class="price color-down">{{ order.price }}</span>
                                    <span class="amount">{{ order.volume }}</span>
                                    <span class="total">{{ order.total }}</span>
                                    <span class="progress-bar ask-bar" :style="'width:' + order.width"></span>
                                </p>
                            </div>
                            <div class="bids">
                                <p @click="onPriceSelected(order.price)" v-for="order in order_books.bids" v-bind:key="order.key">
                                    <span class="price color-up">{{ order.price }}</span>
                                    <span class="amount">{{ order.volume }}</span>
                                    <span class="total">{{ order.total }}</span>
                                    <span class="progress-bar bid-bar" :style="'width:' + order.width"></span>
                                </p>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
            <div class="trade bg-content">
                <div class="mod trade-panel">
                    <div class="mod-head tabs bg-title">
                        <span data-trade-type="exchange" class="mock-a cur">
                            {{ $t('futures.label_trade') }}
                        </span>
                        <div class="actions">
                            <router-link to="/user/balance">{{ $t('general.deposit') }}</router-link>
                        </div>
                    </div>

                    <!-- create orders here -->
                    <create-order-panel ref="createOrderPanel" :symbol="symbol" :futuresConfig="futuresConfig" @create-order="onCreateOrder" />
                </div>
            </div>
        </div>

        <!-- Have to wrap the modal with a section element to avoid layout conflict with other elements: .content-top > div -->
        <section>
            <!-- order confirmation modal -->
            <order-confirmation-modal ref="createOrderModal" :symbol="symbol" />
        </section>
    </div>
</template>
<style scoped>
.content-top{
    position:relative;
}
.content-top .dropdown{
    position: initial;
}
.content-top .dropdown .side_right{
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    background: #000;
    color: #fff;
    z-index: 999999999;
    overflow: hidden;
    border: 1px solid #999;
    top: 40px;
    transform: none;
    left: 0;
    right: 0;
}
.futures-content{
   overflow: auto;
   height: 60vh;
}
.futures-content a,.side_right .form-control{
   color: #000;
}
.bg-content input{
    border: 1px solid #E1E2E6;
}
.trade-layout a {
   font-size: 14px;
}
.futures-content a:hover{
   background: #0A2B9A ;
   color: #fff;
}
.input-group{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 20px;
}
.input-group  .input-group-text,.input-group  .form-control{
    height: 30px;
    background: #fff;
    border: 1px solid #1a1c24;
}
.input-group  .input-group-text{
    width: 40px;
    height: 30px;
    background: #fff;
    border: 1px solid #1a1c24;
}
.input-group .form-control:focus{
    border: none;
}
.form-control{
    color: #7e8697;
}
.custom-arrow {
  position: relative;
  padding-right: 25px; 
}


.custom-arrow::after, .custom-arrow::before {
  content: '';
  position: absolute;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.custom-arrow::after {
  top: 8px;
  border-top: 6px solid #494949;
}
</style>
<script>
import { getSymbolManagerAsync } from 'utilities/helper';
import * as chart from 'utilities/QuoteDataFeed.js';
import { Dropdown } from 'bootstrap';

import CreateOrderPanel from './Futures_CreateOrderPanel.vue';
import OrderConfirmationModal from './Futures_OrderConfirmationModal.vue';

// The date time when the last quote is received.
let g_lastQuoteTime = new Date();

export default {
    components: { CreateOrderPanel, OrderConfirmationModal },

    props: ['symbol', 'futuresConfig'],

    data() {
        return {
            order_books: {
                asks: [],
                bids: []
            },

            symbols: [],
            chartVersion: 0,

            // Indicates whether the component has been destoyed or not.
            destroyed: false,

            isDropdownVisible: false,
            isDropdownVisibleLeft: false
        };
    },

    created() {
        this.destroyed = false;
        this.getFuturesSymbols();
    },

    beforeDestroy() {
        console.log(`## Destroy the futures page.`);
        this.destroyed = true;

        // Stop socket io connections
        $.closeSocketIo();
        chart.destroyChart();
    },

    mounted() {
        this.initChartAsync();

        const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
        const dropdownList = [...dropdownElementList].map(elm => {
            $(elm).click(() => {
                const d = Dropdown.getOrCreateInstance(elm);
                d.show();
            });
        });
    },

    methods: {
        closeDropdownFun(){
            this.isDropdownVisible=false;
            this.isDropdownVisibleLeft=false 
        },
        toggleDropdown() {
            this.isDropdownVisibleLeft = false;
            this.isDropdownVisible = !this.isDropdownVisible;
        },
        closeDropdown() {
            this.isDropdownVisible = false;
        },
        toggleDropdownLeft() {
            this.isDropdownVisibleLeft = !this.isDropdownVisibleLeft;
            this.isDropdownVisible = false;
        },
        closeDropdownLeft() {
            this.isDropdownVisibleLeft = false;
        },
        initChartAsync: async function () {
            const sym = this.symbol;
            const self = this;

            // Clear any existing klines
            chart.setInitialKlines(null);

            // Read initial klines
            const end_ts = Math.floor(new Date().setSeconds(0, 0) / 1000);
            const from_ts = end_ts - 1500 * 60; // request klines for past 1500 minutes.
            const request_url = `/api/v1/quotation/klines?id=${encodeURIComponent(sym.metadata.id)}&type=1&from=${from_ts}&to=${end_ts}&limit=1500`;

            try {
                const resp = await $.callPostApi(self, request_url);
                if (resp) {
                    chart.setInitialKlines(resp);
                }
            } catch (err) {
                console.log(`Failed to read initial klines: ${err}`);
            }

            // Initialize the tradingview chart
            chart.initializeChart(sym, {
                region: this.sysconfig.region,
                locale: this.$i18n.locale,
                uiVersion: this.$version,
                defaultInterval: this.futuresConfig.defaultInterval,
                tzOffset: this.sysconfig.tzOffset
            });

            // Start a socket io connection
            g_lastQuoteTime = new Date();
            const chartVersion = ++this.chartVersion;

            $.initSocketIo(
                '/f' + this.symbol.metadata.id,
                (quote) => {
                    if (self.destroyed === false) {
                        if (self.chartVersion !== chartVersion) {
                            return;
                        }

                        // Is there a big gap for the latest quote?
                        let now = new Date();
                        const gap = now - g_lastQuoteTime;
                        if (gap > 300 * 1000) {
                            setTimeout(() => {
                                console.log('#### rebuild chart ######');
                                $.closeSocketIo();
                                self.initChartAsync();
                            }, 0);
                        } else {
                            g_lastQuoteTime = now;

                            // Update quote.
                            sym.updateRtqs(quote);
                            chart.updateRtqsToChart(quote);

                            // Notify that the price is updated.
                            self.$emit('price-changed', quote);
                        }
                    }
                },
                (depth) => {
                    // Parse depth data
                    self.updateOrderBooks(depth);
                }
            );
        },

        parseDepth: function (src, key_prefix) {
            const pricePrecison = this.symbol.metadata.pricePrecision;
            const amountPrecison = this.symbol.metadata.volumePrecision;
            const self = this;
            let total_vol = 0;
            const arr = [];
            let median = self.medianCalc(src)
            for (let i = 0; i < src.length; i++) {
                let item = src[i];
                total_vol += item[1];
                arr[i] = {
                    key: key_prefix + i,
                    price: item[0].toFixed(pricePrecison),
                    volume: item[1].toFixed(amountPrecison),
                    total: total_vol.toFixed(amountPrecison),
                    width: 100 * Math.min(item[1].toFixed(amountPrecison) / median, 1).toFixed(2) + '%'
                };
            }

            return arr;
        },

        updateOrderBooks: function (depth) {
            this.order_books.bids = this.parseDepth(depth.buy, 'bid');
            this.order_books.asks = this.parseDepth(depth.sell, 'ask');
        },

        medianCalc(array) {
            // 深拷贝  sort会修改原数组，如果数组过大会影响性能
            let arr = JSON.parse(JSON.stringify(array))
            arr.sort((a, b) => {
                if (a[1] < b[1]) return -1
                if (a[1] > b[1]) return 1
                return 0
            })
            //求中位数
            if (arr.length % 2 == 0) {
                return (arr[arr.length / 2 - 1][1] + arr[arr.length / 2][1]) / 2
            } else {
                return arr[Math.floor(arr.length / 2)][1]
            }
        },


        getFuturesSymbols: function () {
            const self = this;
            getSymbolManagerAsync().then((mgr) => {
                self.symbols = mgr.getSymbols(2); // 2: futures symbol
            });
        },

        setAvailableMargin: function (margin) {
            this.$refs.createOrderPanel.setAvailableMargin(margin);
        },

        showTrade: function (up) {
            this.$refs.createOrderPanel.setOrderType(up);
            $('.trade-layout').addClass('skip-chart');
        },

        showChart: function () {
            $('.trade-layout').removeClass('skip-chart');
        },

        onPriceSelected: function (price) {
            // Do nothing as we don't support pending orders now.
            // this.$refs.createOrderPanel.setOrderPrice(price);
        },

        onOrderCreated: function () {
            this.$emit('order-created');
        },

        onCreateOrder: function (options) {
            this.$refs.createOrderModal.openModal(options);
        }
    }
};
</script>