<template>
  <section class="page login-page layout-login d-flex">
      <div class="d-flex flex-row login-left-right flex-fill">
          <!-- <div class="login-page-left">Left component</div> -->
          <div class="login-page-right login-container">
              <div class="img">
                  <img src="../../assets/images/eu3/loginLeftImg2x.png" alt="" />
              </div>
              <div class="login-box">
                  <div class="login container ">
                      <div class="login-inner" id="login-page">
                          <login-form-component v-if="regions" v-bind:regions="regions" v-bind:csrfToken="csrfToken" />
                          <loading-indicator v-else />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<style scoped lang="less">
.login-page {
  background: #17E7B6;
}
.login-left-right {
  margin: auto;
  width: 100%;
}
.login-container {
  padding: 0 70px;
  display: flex;
  justify-content: space-between;
  background-image: url(../../assets/images/eu3/loginPageBc2x.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto;
}
.login-container .login-box {
  background: none;
  width: auto;
  padding-left: 0;
  position: relative;
  flex: 1;
}
.login-container .img .tips {
  width: 100%;
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 10px;
  color: #FEFFFF;
  line-height: 10px;
  text-align: center;
  font-style: normal;
  background-color:initial;
  margin: 0;
  padding: 0 10px;
  line-height: 16px;
}
.login-container .img .title{
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
  padding: 0 15px;
  margin: 15px;
}
.img {
  width: 50%;
  margin: 0;
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
}
.login-container > div img {
  height: 100%;
  width: auto;
  margin: 0;
}
#login-page {
  padding-top: 40px;
  background: #FFFFFF;
  box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.0462);
  border: 4px solid #000000;
  border-radius: 40px;
}
.img-box-md {
  width: 100%;
  background-color: #8C89EB;
  height: 12rem;
  position: relative;
}
.img-box-md .loginGrid {
  max-width: 13.5625rem;
  height: auto;
  position: absolute;
  bottom: -75%;
}
.img-box-md .loginPlane {
  max-width: 6rem;
  height: auto;
  position: absolute;
  bottom: -0.625rem;
  left: 60%;
}
.img-box-md .loginBall {
  max-width: 3rem;
  height: auto;
  z-index: 999;
  position: absolute;
  bottom: -1rem;
  right: 10%;
}
@media (max-width: 1024px) {
  .login-container .img {
      display: none !important;
  }

  .login-container .login-box {
      width: 100%;
  }
  .login {
      left: 50%;
  }
  .login-page .login h1 {
      color: #000;
  }
  .img{
      display: none;
  }
}
@media (max-width: 768px) {
  .login-page {
      min-height:100vh;
  }
  .login-container {
      padding: 0;
      display: block;
      background-image: url(../../assets/images/eu3/loginBcMd2x.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      background-color: #002B29;
  }
  .login-container .login-box {
      padding: 0;
      height: auto;
      margin-top: -25px;
      border-radius: 30px;
      overflow: hidden;
  }
  .login-box .container {
      min-width: auto;
      margin: 0;
      width: 100%;
  }
  .login {
      position: static;
      transform: none;
      background: none;
  }
  #login-page {
      border-radius: 0;
      padding: 60px 16px;
      background: none;
      width: 100%;
      border: none;
      border-radius: 0;
      max-width: none;
  }
}
</style>

<script type="text/javascript">
import LoginFormComponent from './Components/LoginForm.vue';
import { getAppContext } from 'utilities/helper';

export default {
  components: { LoginFormComponent },
  data() {
      return { regions: null, csrfToken: null };
  },

  created() {
      // Already signed in?
      const context = getAppContext();
      const profile = context.profile;
      if (profile) {
          this.$router.push('/user');
      } else {
          this.initAsync();
      }
  },

  watch: {
      '$i18n.locale': function () {
          this.reloadRegionsAsync();
      }
  },

  methods: {
      initAsync: async function () {
          const self = this;

          // Read a list of supported regions.
          const resp = await $.callGetApi(self, '/api/v1/config/regions');


          // Read csrf token
          const token = await $.callPostApi(self, '/api/v1/csrftoken');
          if (token && typeof token === 'string') {
              self.regions = resp.data;
              self.csrfToken = token;
          }
      },

      reloadRegionsAsync: async function () {
          // Read a list of supported regions.
          const resp = await $.callGetApi(self, '/api/v1/config/regions');
          if (resp && resp.data) {
              this.regions = resp.data;
          }
      }
  }
};
</script>