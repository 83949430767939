<template>
    <form :class="{ 'd-none': !showInputs }" class="login-form" method="post" action="javascript:void(0);" @submit="createAccount">
        <div class="title-box">
            <img src="@/assets/images/eu3/loginLogoMd2x.png" alt="" id="login-logo">
            <h6 class="login-title">{{ $t('home.guide_register_long') }}</h6>
        </div>
        <div class="page-top-nav mb-3" v-if="config.phoneSupported && config.emailSupported">
            <li :class="{ active: !emailVCode }">
                <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
            </li>
        </div>

        <div class="form-group">
            <template v-if="emailVCode">
                <!-- Email account -->
                <label class="form-label" for="Name">{{ $t('register.label_email') }}</label>
                <!-- Required by the interface -->
                <input v-model="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" :placeholder="$t('register.label_email')" />
            </template>
                <template v-else>
                <!-- Phone account -->
                <label class="form-label" for="Name">{{ $t('register.label_phone') }}</label>
                <div class="d-flex">
                    <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                    <div class="flex-fill">
                        <input
                            v-model.trim="account"
                            name="Name"
                            type="text"
                            autocomplete="off"
                            pattern="\d*"
                            data-val="true"
                            data-val-required="*"
                            data-val-regex="*"
                            data-val-regex-pattern="^0?[1-9]\d+$"
                            class="form-control round-right"
                            :placeholder="$t('user.label_account')"
                        />
                    </div>
                </div>
            </template>
        </div>
        <div class="form-group">
            <label class="form-label">{{ $t('register.label_password') }}</label>
            <div class="password-box">
                <input
                    name="Password"
                    v-model.trim="password"
                    :type="showPassword?'text':'password'"
                    autocomplete="new-password"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    data-val-maxlength="*"
                    data-val-maxlength-max="32"
                    data-val-minlength="*"
                    data-val-minlength-min="2"
                    :placeholder="$t('register.label_password')"
                />
                <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group">
            <label class="form-label">{{ $t('register.label_confirm_password') }}</label>
            <div class="password-box">
                <input
                    name="ConfirmPassword"
                    v-model.trim="confirmPassword"
                    :type="showConfirmPassword?'text':'password'"
                    autocomplete="new-password"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    data-val-equalto="*"
                    data-val-equalto-other="Password"
                    data-val-maxlength="*"
                    data-val-maxlength-max="32"
                    data-val-minlength="*"
                    data-val-minlength-min="6"
                    :placeholder="$t('register.label_confirm_password')"
                />
                <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group" v-if="emailVCode">
            <label class="form-label" for="Phone">{{ $t('register.bind_phone') }}</label>
            <div class="d-flex">
                <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                <div class="flex-fill">
                    <input v-model.trim="Phone" name="Phone" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" :placeholder="$t('register.label_phone')" />
                </div>
            </div>                
        </div>
        <div class="form-group">
            <label class="form-label">{{ $t('register.label_referral_code') }}</label>
            <div class="code">
                <input
                    type="text"
                    name="ReferralCode"
                    v-model.trim="refCode"
                    autocomplete="off"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    :placeholder="$t('register.label_referral_code')"
                />
            </div>
        </div>

        <!-- Do we need SMS verification? or just use a simple image verification code? -->
        <sms-verification v-if="config.signupVCodeRequired" :regionCode="regionCode" :emailVCode="emailVCode" :account="account" action="signup" />
        <template v-else>
            <div class="form-group">{{ $t('register.label_verification_desp') }}: {{ regionCode }} {{ account }}</div>
            <!-- <vcode-image /> -->

            <!-- Use a dummy sms code to make the API happy. -->
            <input type="hidden" name="Code" value="000000" />
        </template>

        <!-- <button class="btn btn-submit" type="submit">{{ $t('register.label_next_step') }}</button> -->
        <div class="btn-box">
            <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
                {{ $t('register.label_register') }}
            </button>
        </div>
        <div class="text-center form-group">
            <router-link class="login-link" to="/user/login">{{ $t('register.label_login') }}</router-link>
        </div>
    </form>
</template>
<style type="text/css" scoped>
.login-form {
    width: 100%;
}
.title-box {
    display: none;
}
.page-top-nav{
    display: flex;
    margin-bottom: 2.375rem !important;
}
.page-top-nav li{
    width: 50%;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}
.page-top-nav li a {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    height: 100%;
    line-height: 50px;
}
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
.form-label {
    display: none;
}
#login-page .btn-box {
    background: #5CCD9E;
    box-shadow: 0px 4px 0px 0px rgba(0,0,0,0.3), inset 0px -4px 0px 0px #1F7853;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid;
    padding: 4px 4px 8px;
}
#login-page button.btn-submit {
    margin: 0;
    display: block;
    width: 100%;
    height: 100%;
    padding: .625rem 0;
    background: linear-gradient( 180deg, #AEEDD2 0%, #92F6CC 100%);
    box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.3), inset 0px -9px 10px 0px #74DCB0;
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #1F7853;
    font-size: 20px;
}
.help-block {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #7F8490;
}
.form-group {
    margin-bottom: 1.5625rem;
    font-size: 16px;
}
.form-group .form-control {
    font-size: 16px;
    outline: none;
    border-radius: 16px 16px 16px 16px;
    border: 1px solid #7F8490;
    background: none;
    padding: 15px 20px;
}
.form-group .form-control:focus {
    outline: none;
    border-color: #000000;
}
.login-link {
    font-size: 12px;
    color: #7F8490;
}
@media (max-width: 768px) {
    .login-page .form-group {
        margin-bottom: 10px;
    }
    .login-page .form-group label {
        display: block;
        font-family: PingFang SC, PingFang SC;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 35px;
    }
    .form-group .form-control {
        border: 1px solid #FFFFFF;
        border-radius: 8px;
        background: rgba(241,242,245,0.3);
        padding: 13px 16px;
        color: #FFFFFF;
    }
    .form-group .form-control::placeholder {
        color: #FFFFFF;
    }
    .form-group .form-control:focus {
        border: 1px solid #FFFFFF;
    }
    .page-top-nav li.active a {
        color: #FFFFFF;
    }
    #login-page button.btn-submit {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
    }
    .page-top-nav {
        margin-bottom: 1rem !important;
    }
    #login-page .btn-box {
        margin-top: 30px;
    }
    .title-box {
        display: block;
    }
    #login-logo {
        width: 47px;
        height: auto;
        margin: 0;
        margin-bottom: 16px;
    }
    .login-title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
    }
}
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import { setProfile } from 'utilities/helper';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';

export default {
    components: { RegionSelector },
    props: ['config', 'regions', 'referral'],

    data() {
        return {
            regionCode: null,
            account: null,
            password: null,
            confirmPassword: null,
            refCode: null,
            showInputs: true,

            // Submit status
            submitting: false,
            completed: false,
            emailVCode:false,
            showPassword:false,
            showConfirmPassword:false
        };
    },

    created() {
        const config = this.config;
        if (!config || config.signupEnabled !== true) throw new Error('signupEnabled must be true when creating new accounts.');

        const regions = this.regions;
        if (!Array.isArray(regions)) throw new Error('An array of supported regions are required.');

        this.refCode = this.referral;
    },

    watch: {
        referral: function () {
            this.refCode = this.referral;
        },
        emailVCode: function () {
            Vue.nextTick(() => {
                $.resetValidators();
            });
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        // goNextPage: function (e) {
        //     const frm = $(e.target);
        //     if (frm.valid()) {
        //         this.showInputs = false;
        //     }
        //     return false;
        // },

        // goPrevPage: function () {
        //     this.showInputs = true;
        // },

        createAccount: function (e) {
            const frm = $(e.target);
            if (!frm.valid()) return false;

            // post the server
            const data = frm.serialize();
            const self = this;
            self.submitting = true;

            $.callPostApi(self, '/api/v1/account/create', data)
                .then((json) => {
                    if (json && json.errcode === 0) {
                        // Save the current authenticated user.
                        setProfile(json.data);

                        // redirect to home page
                        self.completed = true;

                        setTimeout(function () {
                            self.$router.push('/user/verifyid');
                        }, 500);
                    }else if(json.errcode === 103){
                        // 什么也不做，保持loading状态
                    } else {
                        self.submitting = false;
                        $.top_error(json.errmsg);
                    }
                })
                .catch(function (err) {
                    self.submitting = false;
                    $.top_error(self.$t('general.operation_error'));
                })
        },

        onRegionChanged: function (val) {
            console.log(`## Region changed to ${val}`);
            this.regionCode = val;
        }
    }
};
</script>