<template>
    <div class="container">
       <div class="container first-part">
            <div class="first-part-left">
                <div class="first-part-title">{{ $t('home.home_experience') }}</div>
                <div class="first-part-desp">{{ $t('home.home_experience') }}</div>
                <div class="first-part-content1">{{ $t('home.footer_slogen') }}</div>
                <div class="first-part-content2">{{ $t('home.footer_slogen') }}</div>
                <div class="btn-box">
                    <router-link to="/user/create" class="go-register">{{ $t('register.label_register') }}</router-link>
                </div>
            </div>
            <div class="first-part-right">
                <img src="@/assets/images/eu3/homeTopBox.png" alt="">
            </div>
       </div>
       <div class="home-card-list">
            <div class="home-card-item">
                <div class="card-content">
                    <div class="card-yellow-text">{{ $t('home.guide_trade_long') }}</div>
                    <div class="card-desp">{{ $t('home.features_online_service') }}</div>
                    <div class="card-yellow-text">{{ $t('home.guide_trade_long') }}</div>
                </div>
                <div class="card-img">
                    <img src="../../../assets/images/eu3/homeCard1.png" alt="">
                </div>
            </div>
            <div class="home-card-item">
                <div class="card-content">
                    <div class="card-yellow-text">{{ $t('home.guide_trade_long') }}</div>
                    <div class="card-desp">{{ $t('home.features_online_service') }}</div>
                    <div class="card-yellow-text">{{ $t('home.guide_trade_long') }}</div>
                </div>
                <div class="card-img">
                    <img src="../../../assets/images/eu3/homeCard2.png" alt="">
                </div>
            </div>
            <div class="home-card-item">
                <div class="card-content">
                    <div class="card-yellow-text">{{ $t('home.guide_trade_long') }}</div>
                    <div class="card-desp">{{ $t('home.features_online_service') }}</div>
                    <div class="card-yellow-text">{{ $t('home.guide_trade_long') }}</div>
                </div>
                <div class="card-img">
                    <img src="../../../assets/images/eu3/homeCard3.png" alt="">
                </div>
            </div>
            <div class="home-card-item">
                <div class="card-content">
                    <div class="card-yellow-text">{{ $t('home.guide_trade_long') }}</div>
                    <div class="card-desp">{{ $t('home.features_online_service') }}</div>
                    <div class="card-yellow-text">{{ $t('home.guide_trade_long') }}</div>
                </div>
                <div class="card-img">
                    <img src="../../../assets/images/eu3/homeCard4.png" alt="">
                </div>
            </div>
       </div>
        <section class="home-section">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h2 class="table-title">
                            {{ $t('home.home_transactions') }}
                        </h2>
                        <div class="main-block-wrapper">
                            <home-quotes ref="quotes" :symbols="symbols" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
       <div class="container two-part"> 
            <div class="two-part-left">
                <div class="title">{{$t("home.home_transactions")}}</div>
                <h6 class="sub-title">{{ $t('home.h1') }}</h6>
                <div class="num-box">
                    <div class="num-item-box">
                        <div class="num-item">{{ $t('user.label_premium_user') }}</div>
                        <div class="num-desp">{{ $t('user.label_id_verified') }}</div>
                    </div>
                    <div class="num-item-box">
                        <div class="num-item">{{ $t('user.label_premium_user') }}</div>
                        <div class="num-desp">{{ $t('user.label_id_verified') }}</div>
                    </div>
                    <div class="num-item-box">
                        <div class="num-item">{{ $t('user.label_premium_user') }}</div>
                        <div class="num-desp">{{ $t('user.label_id_verified') }}</div>
                    </div>
                </div>
                <router-link to="/quotes" class="go-quotes">{{ $t('home.label_more') + '>'}}</router-link>
            </div>
            <div class="two-part-right">
                <img src="@/assets/images/eu3/rightWomen.png" alt="">
            </div>
        </div>
        <inline-svg-icons :symbols="symbols" />
        <div class="container three-part">
            <div class="title">{{$t("home.h1")}}</div>
            <div class="title-desp">{{ $t('home.h1_desp') }}</div>
            <div class="safe-box">
                <div class="safe-item">
                    <img src="../../../assets/images/eu3/homeSafe1.png" alt="">
                    <h5 class="safe-item-title">{{ $t('intro.why_us_reliable_title') }}</h5>
                    <div class="safe-item-desp">{{ $t('intro.why_us_stable_desp') }}</div>
                </div>
                <div class="safe-item">
                    <img src="../../../assets/images/eu3/homeSafe2.png" alt="">
                    <h5 class="safe-item-title">{{ $t('intro.why_us_reliable_title') }}</h5>
                    <div class="safe-item-desp">{{ $t('intro.why_us_stable_desp') }}</div>
                </div>
                <div class="safe-item">
                    <img src="../../../assets/images/eu3/homeSafe3.png" alt="">
                    <h5 class="safe-item-title">{{ $t('intro.why_us_reliable_title') }}</h5>
                    <div class="safe-item-desp">{{ $t('intro.why_us_stable_desp') }}</div>
                </div>
            </div>
        </div>
        <div class="container four-part">
            <div class="four-part-left">
                <img src="@/assets/images/eu3/homeTradePhone.png" alt="">
            </div>
            <div class="four-part-right">
                <div class="four-part-title">{{ $t('intro.any_platform') }}</div>
                <router-link to="/futures" class="go-quotes">{{ $t('home.label_more') + '>'}}</router-link>
            </div>
        </div>
        <div class="container five-part">
            <div class="support-title">{{ $t('intro.multi_apps') }}</div>
            <div class="support-list">
                <a href="javascript:;"><img src="@/assets/images/eu3/support1.png" alt=""></a>
                <a href="javascript:;"><img src="@/assets/images/eu3/support2.png" alt=""></a>
                <a href="javascript:;"><img src="@/assets/images/eu3/support3.png" alt=""></a>
                <a href="javascript:;"><img src="@/assets/images/eu3/support4.png" alt=""></a>
            </div>
        </div>
        <div class="container six-part">
            <div class="six-part-title">{{ $t('intro.why_us_title') }}</div>
            <div class="six-part-desp">{{ $t('intro.why_us_reliable_title') }}</div>
            <div class="data-list">
                <div class="data-item-box">
                    <div class="data-item">{{ $t('home.24h_change') }}</div>
                    <div class="data-desp">{{ $t('home.24h_vol') }}</div>
                </div>
                <div class="data-item-box">
                    <div class="data-item">{{ $t('home.24h_change') }}</div>
                    <div class="data-desp">{{ $t('home.24h_vol') }}</div>
                </div>
                <div class="data-item-box">
                    <div class="data-item">{{ $t('home.24h_change') }}</div>
                    <div class="data-desp">{{ $t('home.24h_vol') }}</div>
                </div>
                <div class="data-item-box">
                    <div class="data-item">{{ $t('home.24h_change') }}</div>
                    <div class="data-desp">{{ $t('home.24h_vol') }}</div>
                </div>
            </div>
            <router-link to="/user/create" class="go-register">{{ $t('general.register') }}&#8594;</router-link>
        </div>
    </div>
</template>
<script>
import HomeQuotes from './../Components/HomeQuotes.vue';
import InlineSvgIcons from '../../Components/_InlineSvgIcons.vue';
export default {
    components: {  HomeQuotes, InlineSvgIcons },
    props: ['symbols'],
    data() {
        return {
           RegionCode:""
        }
    },
    created() { },
    methods: {
        getVisibleSids: function () {
            return this.symbols ? this.$refs.quotes.getVisibleSids() : [];
        }
    }
}
</script>
<style lang="less" scoped>
.home-section{
    margin-top: 30px;
    .table-title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 40px;
        color: #FFFFFF;
        margin-bottom: 20px;
        text-align: center;
    }
}
.first-part{
    display: flex;
    width: 100%;
    margin-top: 30px;
    >.first-part-left{
        width: 49.5%;
        margin-right: 1%;
        .first-part-title {
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 45px;
            color: #FFFFFF;
        }
        .first-part-desp {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 20px;
            color: #888888;
            line-height: 28px;
            margin: 15px 0 18px;
        }
        .first-part-content1 {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 28px;
            margin-bottom: 30px;
        }
        .first-part-content2 {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 28px;
            margin-bottom: 22px;
            text-indent: 1.5em;
        }
        .btn-box {
            width: 100%;
            text-align: center;
        }
        .go-register {
            padding: 12px 46px;
            background: #03AAC7;
            border-radius: 23px 23px 23px 23px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            display: inline-block;
        }
    }
    >div:nth-child(2){
       margin-right: 0;
       img{
            width: 100%;
            height: auto;
        }
    }
}
.home-card-list {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
    margin: 140px 0 80px;
    .home-card-item {
        flex: 1;
        display: flex;
        background: #1B1B1B;
        border-radius: 10px;
        overflow: hidden;
        padding: 20px;
        .card-content .card-yellow-text {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFB904;
            line-height: 20px;
        }
        .card-content .card-blue-text {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #00F0FF;
            line-height: 20px;
        }
        .card-content .card-desp {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #9E9E9E;
            line-height: 22px;
        }
        .card-img {
            width: 40%;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
.two-part{
    color: #fff;
    margin: 80px 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .two-part-left {
        .title{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 40px;
            color: #FFFFFF;
            line-height: 70px;
            margin-bottom: 32px;
        }
        .sub-title {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 28px;
            margin-bottom: 78px;
        }
        .num-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .num-item {
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 32px;
                color: #FFFFFF;
                line-height: 45px;
            }
            .num-desp {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #AAAAAA;
                line-height: 17px;
            }
        }
        .go-quotes {
            background: #03AAC7;
            border-radius: 279px 279px 279px 279px;
            border: 1px solid #EBEBEE;
            padding: 10px 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            display: inline-block;
            margin-top: 48px;
            margin-bottom: 140px;
        }
    }
    .two-part-right {
        flex: 0 0 40%;
        display: flex;
        align-items: end;
        img {
            width: 100%;
            height: auto;
        }
    }
}
.three-part{
    margin-bottom: 178px;
    .title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 40px;
        color: #FFFFFF;
        line-height: 70px;
        margin-bottom: 32px;
    }
    .title-desp {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #AAAAAA;
        line-height: 28px;
        margin-bottom: 80px;
    }
    .safe-box {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 48px;
        .safe-item {
            flex: 1;
            border-radius: 20px 20px 20px 20px;
            border: 1px solid #AAAAAA;
            padding: 24px;
            img {
                width: 70%;
                height: auto;
                margin-bottom: -30px;
            }
            .safe-item-title {
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 20px;
                color: #FFFFFF;
                line-height: 34px;
                margin-bottom: 24px;
            }
            .safe-item-desp {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #AAAAAA;
                line-height: 28px;
            }
        }
    }
}
.four-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .four-part-left {
        width: 49%;
        img {
            width: 100%;
            height: auto;
        }
    }
    .four-part-right {
        flex: 1;
        text-align: center;
        .four-part-title {
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 40px;
            color: #03AAC7;
            line-height: 70px;
            margin-bottom: 44px;
        }
        .go-quotes {
            background: #03AAC7;
            border-radius: 279px 279px 279px 279px;
            border: 1px solid #EBEBEE;
            padding: 10px 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            display: inline-block;
        }
    }
}
.five-part {
    margin: 100px 48px;
    .support-title {
        font-family: PingFangHK-Medium, PingFangHK-Medium;
        font-weight: 400;
        font-size: 40px;
        color: #FFFFFF;
        line-height: 70px;
        margin-bottom: 48px;
        text-align: center;
    }
    .support-list {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 32px;
        a {
            display: inline-block;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
.six-part {
    padding: 80px 0;
    text-align: center;
    .six-part-title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 40px;
        color: #FFFFFF;
        line-height: 70px;
        margin-bottom: 40px;
        text-align: center;
    }
    .six-part-desp {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #AAAAAA;
        line-height: 28px;
        margin-bottom: 100px;
        text-align: center;
    }
    .data-list {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        text-align: left;
        .data-item {
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 36px;
            color: #FFFFFF;
            line-height: 50px;
            margin-bottom: 20px;
        }
        .data-desp {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #AAAAAA;
            line-height: 22px;
        }
    }
    .go-register {
        padding: 12px 46px;
        background: #03AAC7;
        border-radius: 23px 23px 23px 23px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        display: inline-block;
        margin: 60px auto;
    }
}

</style>
