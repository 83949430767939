<template>
    <section class="page home-page">
        <section class="pro_layout">
            <loading-indicator v-if="!symbols" />
            <div v-else>
                <div class="home-top">
                    <top-banners v-if="context.homeConfig" :home_config="context.homeConfig" />
                </div>

                <!-- top notice -->
                <div class="page-part" v-if="context.homeConfig && context.homeConfig.promptedNotices && context.homeConfig.promptedNotices.length">
                    <home-notices :promptedNotices="context.homeConfig.promptedNotices" />
                </div>

                <!-- major nav icons for sm screens -->
                <div class="d-block d-md-none sm-navs">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <router-link to="/user/login">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-login" />
                                    </svg>
                                    {{ $t('general.login') }}/{{ $t('general.register') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link v-if="sysconfig.savingsEnabled" to="/saving">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                                    </svg>
                                    {{ $t('home.header_saving', { platform: sysconfig.name }) }}
                                </router-link>
                                <router-link v-else to="/user/balance">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                                    </svg>
                                    {{ $t('home.header_buy_crypto') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link to="/startup">
                                    <svg class="svg-icon" viewBox="0 0 256 256">
                                        <use xlink:href="/dist/svg/icons.svg#v4.2-assets" />
                                    </svg>
                                    {{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}
                                </router-link>
                            </div>
                            <div class="col">
                                <router-link to="/notices">
                                    <div class="svg-box"> <svg class="svg-icon notice-svg" viewBox="0 0 256 256">
                                            <use class="use-svg" xlink:href="/dist/svg/icons.svg#v4.2-notice" />
                                        </svg>
                                    </div>
                                    {{ $t('notices.notice_title') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <introduction  class="d-none d-md-block" ref="quotes" :symbols="symbols" />
                <IntroductionSmall  class="d-block d-md-none" ref="quotes"  :symbols="symbols" />
                <partner-links v-if="context && context.homeConfig && context.homeConfig.spl === true" />
            </div>
        </section>
    </section>
</template>

<style scoped>
.page{
    background: #000;
}
.sm-navs{
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-align: center;
    background: #1b1b1b;
}
.sm-navs a {
    color: #fff;
    font-size: 12px;
}
.sm-navs .container .col{
    width: 20%;
}
.sm-navs svg.svg-icon {
    fill: var(--primary-base);
    display: block;
    height: 1.5rem;
    margin: auto auto .5rem;
    width: auto;
}
.sm-navs svg.svg-icon.notice-svg {
    height: 2.8rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
.svg-box {
    height: 1.5rem;
    margin-bottom: .5rem;
    position: relative;
}
@media screen and ( min-width :768px) and ( max-width :1024px) {
    .d-md-none {
        display: block !important;
    }
    .d-md-block{
        display: none !important;
    }
}

</style>

<script type="text/javascript">
import SymbolList from './Components/SymbolList.vue';
import TopBanners from './Components/TopBanners-4.2.vue';
import HomeNotices from './Components/HomeNotices.vue';
import HomePosts from './Components/HomePosts.vue';
import PromptedSymbols from './Components/PromptedSymbols.vue';
import Introduction from './Components/Introduction-4.2.vue';
import IntroductionSmall from './Components/IntroductionSmall.vue';
import HomeQuotes from './Components/HomeQuotes.vue';
import HomeCarousel from './Components/HomeCarousel.vue';
import InlineSvgIcons from '../Components/_InlineSvgIcons.vue';
import PartnerLinks from './Components/_PartnerLinks-4.2.vue';
import '@/assets/svg/icon-login.svg';
import '@/assets/svg/icon-wallet.svg'
import '@/assets/svg/icon-assets.svg';;
import '@/assets/svg/icon-invite.svg';
import '@/assets/svg/icon-notice.svg';


import '@/assets/svg/v4.2/v4.2-login.svg';
import '@/assets/svg/v4.2/v4.2-wallet.svg';
import '@/assets/svg/v4.2/v4.2-assets.svg';
import '@/assets/svg/v4.2/v4.2-notice.svg';

import { SymbolInfo } from 'utilities/SymbolInfo.js';
import { getSymbolManagerAsync, getAppContext } from 'utilities/helper';

let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
    components: { TopBanners, SymbolList, HomeNotices, HomePosts, Introduction, PromptedSymbols, HomeQuotes, HomeCarousel, InlineSvgIcons, PartnerLinks ,IntroductionSmall},

    data() {
        return {
            // All symbols.
            symbols: null,
            // App context
            context: null
        };
    },

    computed: {
        // return a list of prompted symbols
        promptedSymbols: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            return arr;
        }
    },

    created() {
        g_destoryed = false;
        this.context = getAppContext();
        // clear all existing symbols
        g_symbolMap = {};
        this.initPageAsync();
    },

    beforeDestroy() {
        g_destoryed = true;

        // clear all timers
        clearTimeout(g_quoteTimerId);
    },

    methods: {
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;

                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.symbols = arr;

                Vue.nextTick(() => {
                    // start to subscribe realtime quote data
                    self.syncQuotes();
                });
            }
        },

        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        syncQuotes: function () {
            const self = this;
            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const sids = [];
            $(this.promptedSymbols).each((index, sym) => {
                sids.push(sym.metadata.id);
            });

            try {
                const temp = this.$refs.quotes.getVisibleSids();
                if (Array.isArray(temp)) {
                    for (let i = 0; i < temp.length; i++) {
                        sids.push(temp[i]);
                    }
                }
            } catch (err) {
                console.error(err);
            }

            if (sids.length > 0) {
                // Read quotes from server
                self.$http
                    .get('/api/v1/quotation/latest?symbols=' + sids.join(','))
                    .then((json) => {
                        const quotes = json.data;
                        if (quotes.length > 0) {
                            for (let i = 0; i < quotes.length; i++) {
                                const quote = quotes[i];
                                const sym = g_symbolMap[quote.id];
                                if (sym) {
                                    sym.update(quote);
                                }
                            }
                        }
                    })
                    .then(() => {
                        if (g_destoryed === false) {
                            // always restart timer
                            let delay = 4000 + Math.round(Math.random() * 3000);
                            g_quoteTimerId = setTimeout(() => {
                                self.syncQuotes();
                            }, delay);
                        }
                    });
            }
        }
    }
};
</script>
